<template>
  <div
    :id="elementId"
    :class="{ reduced: reduced, fullwidth: fullWidth }"
  ></div>
</template>

<script lang="tsx">
import algoliasearch from 'algoliasearch/lite'
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js'
import { onMounted } from '@vue/composition-api'
import { Fragment, render } from 'vue'
import { createElement } from '~/utils/createElement'
import {
  getCitiesIndexName,
  getCategoriesIndexName,
  getFederatedSearchIndexName,
} from '~/utils/algoliaHelpers'
import type { EventDescription } from '~/types/event'
export default {
  name: 'FederatedSearch',
  props: {
    elementId: {
      type: String,
      required: true,
    },
    reduced: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t, locale } = useI18n()
    const { isMobile } = useDevice()
    const resultsPanelTranslations = {
      faq: t('general.titles.faq'),
      magazine: t('general.magazine'),
      seeAllGiftcards: t('search.filters.seeResults'),
    }
    const router = useRouter()
    const localePath = useLocalePath()
    const config = useRuntimeConfig()
    const searchClient = algoliasearch(
      config.public.algoliaAppId as string,
      config.public.algoliaApiClientKey as string
    )
    const algoliaAutocompleteInstance = ref(null)

    const mountUrlParamsFromItem = (item) => {
      let geoloc = ''
      if (item?._geoloc) {
        geoloc = `geoloc=${item._geoloc.lat},${item._geoloc.lng}`
      }

      const city = 'city=' + encodeURIComponent(item.slug)

      return [geoloc, city].join('&')
    }

    const areThereEventResults = (eventResults) =>
      eventResults?.children?.[1]?.props?.className !== 'aa-SourceNoResults'

    const { lastViewedEvents } = useLastViewedEvents(true)

    onMounted(() => {
      const el = document.getElementById(props.elementId)

      if (el === null || el.childNodes.length > 0) {
        return
      }

      algoliaAutocompleteInstance.value = autocomplete({
        container: `#${props.elementId}`,
        defaultActiveItemId: 0,
        openOnFocus: true,

        translations: {
          detachedCancelButtonText: 'cancel',
        },

        renderer: { createElement, Fragment, render },
        render({ elements, render, Fragment, state, setIsOpen }, root) {
          const { eventCategories, events, localities } = elements
          const areThereCityOrCategoryResults =
            Boolean(localities) || Boolean(eventCategories)
          const shouldShowAllResultsButton =
            areThereCityOrCategoryResults || areThereEventResults(events)
          const shouldShowLastViewedEvents =
            lastViewedEvents.value?.length > 0 && !state?.query
          render(
            <Fragment>
              <div class="aa-PanelLayout aa-Panel--scrollable">
                <div class="aa-PanelSections">
                  <div class="aa-PanelSection--left">
                    <div>{localities}</div>
                    <div class="aa-CategorySection">{eventCategories}</div>
                  </div>
                  {/* algolia result events */}
                  <div
                    class={
                      'aa-PanelSection--right ' +
                      (areThereCityOrCategoryResults
                        ? 'one-column'
                        : 'two-columns') +
                      (shouldShowLastViewedEvents ? ' hidden' : '')
                    }
                  >
                    {events}
                    <div
                      class={
                        'flex ' + (shouldShowAllResultsButton ? '' : 'hidden')
                      }
                    >
                      <a
                        href={localePath(
                          `/search/${
                            state?.query ? '?searchquery=' + state?.query : ''
                          }`
                        )}
                        class="block mt-8 mb-4 ml-2 px-4 py-2 rounded-lg mx-auto text-white bg-primary cursor-pointer"
                      >
                        {t('general.showAllResults')}
                      </a>
                    </div>
                  </div>
                  {/* last viewed events */}
                  <div
                    class={
                      'aa-PanelSection--right ml-4 ' +
                      (!shouldShowLastViewedEvents ? ' hidden' : '')
                    }
                  >
                    <div class="flex  my-4">
                      <span class="aa-SourceHeaderTitle shrink-0">
                        {t('home.lastViewedEvents')}
                      </span>
                      <div className="aa-SourceHeaderLine !relative w-full"></div>
                    </div>
                    {lastViewedEvents?.value
                      ?.slice(0, 5)
                      ?.map((event: EventDescription) => (
                        <div
                          class="aa-ItemContent"
                          onClick={() => {
                            setIsOpen(false)
                            router.push(localePath(`/e/${event.permalink}`))
                          }}
                        >
                          <img
                            class="w-20 h-16 mb-2 object-cover mr-2 rounded-md"
                            src={event?.images?.[0]?.thumb}
                            alt={event?.label}
                          />
                          <span class="aa-ItemContentTitle">
                            {' '}
                            {event?.title}{' '}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Fragment>,
            root
          )
          if (props.fullWidth) {
            const resultsPanel = document.getElementsByClassName('aa-Panel')
            if (resultsPanel && resultsPanel[0]) {
              resultsPanel[0]?.classList?.add('aa-Panel-fullwidth')
            }
          }
        },
        getSources({ setIsOpen }) {
          return [
            {
              sourceId: 'localities',
              getItemInputValue: ({ item }) => item.query,
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: getCitiesIndexName(),
                      query,
                      params: {
                        hitsPerPage: isMobile ? 3 : 6,
                        attributesToSnippet: ['name:24'],
                        snippetEllipsisText: '…',
                        optionalWords: query,
                        aroundLatLngViaIP: true,
                        aroundRadius: 'all',
                        aroundPrecision: [
                          { from: 0, value: 15000 },
                          { from: 30000, value: 30000 },
                          { from: 150000, value: 100000 },
                        ],
                      },
                    },
                  ],
                })
              },
              getItemUrl({ item }) {
                return localePath(`/search/?${mountUrlParamsFromItem(item)}`)
              },
              templates: {
                header() {
                  return (
                    <div class="mr-8">
                      <span class="aa-SourceHeaderTitle">
                        {t('home.header.search.selectCity')}
                      </span>
                      <div className="aa-SourceHeaderLine"></div>
                    </div>
                  )
                },
                item({ item, components, html }) {
                  return html` <div class="aa-ItemContent">
                    ${components.Highlight({
                      hit: item,
                      attribute: 'name',
                    })}
                  </div>`
                },
              },
              onSelect({ item }) {
                setIsOpen(false)
                return router.push(
                  localePath(`/search/?${mountUrlParamsFromItem(item)}`)
                )
              },
            },
            {
              sourceId: 'eventCategories',
              getItemInputValue: ({ item }) => item.query,
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: getCategoriesIndexName(locale.value),
                      query,
                      params: {
                        hitsPerPage: 3,
                        attributesToSnippet: ['name:24'],
                        snippetEllipsisText: '…',
                        optionalWords: query,
                      },
                    },
                  ],
                })
              },
              getItemUrl({ item }) {
                return localePath(`/c/${item?.slug}`)
              },
              templates: {
                header({ html }) {
                  return html`<div>
                    <span class="aa-SourceHeaderTitle"
                      >${t('general.categories')}</span
                    >
                    <div className="aa-SourceHeaderLine"></div>
                  </div>`
                },
                item({ item, components, html }) {
                  return html` <div class="aa-ItemContent">
                    ${components.Highlight({
                      hit: item,
                      attribute: 'name',
                    })}
                  </div>`
                },
              },
              onSelect({ item }) {
                setIsOpen(false)
                return router.push(localePath(`/c/${item.slug}`))
              },
            },
            {
              sourceId: 'events',
              getItemInputValue: ({ item }) => item.query,
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: getFederatedSearchIndexName(locale.value),
                      query,
                      params: {
                        hitsPerPage: 8,
                        attributesToSnippet: ['name:24'],
                        snippetEllipsisText: '…',
                        aroundLatLngViaIP: true,
                        aroundRadius: 'all',
                        aroundPrecision: [
                          { from: 0, value: 15000 },
                          { from: 30000, value: 30000 },
                          { from: 150000, value: 100000 },
                        ],
                      },
                    },
                  ],
                })
              },
              getItemUrl({ item }) {
                // Adding empty space is a workaround to get navigator
                // hook working for Enter key
                return item.url + ' '
              },
              templates: {
                header() {
                  return (
                    <div>
                      <span class="aa-SourceHeaderTitle">
                        {t('general.events')}
                      </span>
                      <div className="aa-SourceHeaderLine"></div>
                    </div>
                  )
                },
                item({ item }) {
                  return (
                    <div class="aa-ItemContent">
                      <img
                        class="w-20 h-16 object-cover mr-2 rounded-md"
                        src={item.images?.[0]?.thumb}
                        alt={item.label}
                      />
                      <span class="aa-ItemContentTitle"> {item.title} </span>
                    </div>
                  )
                },
                noResults() {
                  return t('general.select.noResultsFound')
                },
              },
              onSelect({ item }) {
                setIsOpen(false)
                return item?.supplier === 'konfetti Gutscheine'
                  ? router.push(localePath(`/giftcard/${item.permalink}`))
                  : router.push(localePath(`/e/${item.permalink}`))
              },
            },
          ]
        },
        placeholder: t('search.algoliaAutocomplete.placeholder'),
        onSubmit({ state, setIsOpen }) {
          setIsOpen(false)
          navigateTo({
            path: localePath('/search/'),
            query: {
              searchquery: state?.query,
            },
          })
          state.query = ''
        },
        navigator: {
          navigate({ itemUrl, state }) {
            if (itemUrl?.includes('&city=') || itemUrl?.includes('/c/')) {
              navigateTo(itemUrl)
            } else {
              navigateTo({
                path: localePath('/search/'),
                query: {
                  searchquery: state?.query,
                },
              })
            }
            state.query = ''
          },
        },
      })
    })

    const route = useRoute()

    watch(
      () => route.fullPath,
      () => {
        setTimeout(() => {
          algoliaAutocompleteInstance.value?.setIsOpen(false)
        }, 0)
      }
    )
  },
}
</script>

<style lang="postcss">
@import '~/assets/css/federated-search.css';
</style>
